/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  background: $content-bg;
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  min-height: calc(100vh - #{$navbar-height});
  @include make-container();
  @include make-container-max-widths();
}

.content-wrapper {
  padding: 1.5rem 0;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;

  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

.fade {
  &.show,&.in {
    opacity: 1;
  }
}
