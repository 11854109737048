/* Tabs */
.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;

  input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.list-wrapper {
  height: 100%;
  max-height: 100%;

  ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;

    li {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: 0.9375rem;
      padding: 0.4rem 0;
      border-bottom: 1px solid $border-color;

      .form-check {
        @extend %ellipsor;
        margin-top: 10px;

        .form-check-label {
          @extend%ellipsor;
          .todo-content{
            max-width: 85%;
          }
        }
      }
      .col-content{
        width: 100%;
        >div{
          width: 100%;
        }
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  .remove {
    margin-left: auto;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 600;
    color: theme-color(danger);
    width: 1.25rem;
    height: 1.25rem;
    line-height: 20px;
    text-align: center;

    .rtl & {
      margin-right: auto;
      margin-left: 0;
    }
  }

  .completed {
    text-decoration: line-through;
    text-decoration-color: theme-color(primary);

    .remove {
      text-decoration: none;
    }
  }
}