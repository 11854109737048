/* Tables */
.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 600;
      color: theme-color(dark);

      i {
        margin-left: 0.325rem;
      }
    }
  }

  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    padding: 23px 30px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  &.table-borderless {
    border: none;

    td,
    th,
    tr {
      border: none;
    }
  }
  .table-responsive &{
    th,td{
      white-space: nowrap;
    }
  }
}