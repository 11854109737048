// Accordion Inverse variations
@mixin accordion-inverse-variant($color) {
  &.card {
    background: rgba($color, 0.16);
    color: $color;
    border: none;

    .card-header {
      color: $color;
      border: none;

      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        color: $color;
      }

      a {
        border-color: $color;

        &:before {
          color: $color;
        }

        &[aria-expanded="true"] {
          border-bottom: 1px solid rgba($color, 0.16);
        }

        &[aria-expanded="false"] {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}
@mixin accordion-outline-variant($color) {
  .card {
    border-color: $color;

    .card-header {
      border: none;

      a {
        border-color: $color;

        &:before {
          color: $color;
        }
      }
    }
  }
}
@mixin accordion-multiple-outline-variant($color) {
  border-color: theme-color($color);

  .card-header {
    a {
      .card-icon {
        color: theme-color($color);
      }

      &:before {
        color: theme-color($color);
      }

      &[aria-expanded="true"] {
        border-bottom: 1px solid theme-color($color);
      }

      &[aria-expanded="false"] {
        border-bottom: 1px solid $white;
      }
    }
  }
}
@mixin accordion-multiple-filled-variant($color) {
  .card-header {
    a {
      background: theme-color($color);

      &[aria-expanded="true"] {
        background: theme-color($color);
      }

      &[aria-expanded="false"] {
        background: theme-color($color);
      }
    }
  }
}
