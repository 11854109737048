/* Dashboard */
.card-statistics {
  .highlight-icon {
    height: 53px;
    width: 53px;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    @include border-radius(50px);

    i {
      font-size: 27px;
    }
  }

  .card-col {
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: none;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      border: none;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        &:after,
        &:before {
          content: "";
          position: absolute;
          background: $border-color;
        }
      }

      &:first-child {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          right: 0;
        }

        &:after {
          bottom: 0;
          width: 1px;
          height: 100%;
          right: 0;
        }
      }

      &:nth-child(2) {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          left: 0;
        }
      }

      &:nth-child(3) {
        &:before {
          width: 1px;
          height: 100%;
          right: 0;
        }
      }
    }
  }

  .rtl & {
    .card-col {
      border-right: 1px solid $border-color;
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
  }
}

.product-chart-wrapper {
  height: 92%;
}

.tickets-tab {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: block;
      border-bottom: $border-property;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;

      .details {
        @extend .d-flex;
        @extend .align-items-center;

        div.profile {
          max-width: 40px;
          width: 100%;
          margin-right: 10px;
          margin-bottom: auto;

          img {
            max-width: 100%;
            width: 100%;
            @include border-radius(100%);
          }
        }

        .t-content {
          width: 85%;
          max-width: 100%;

          .sender-content {
            @extend .d-flex;
            margin-right: 25px;

            p.sender-name {
              font-weight: 600;
              color: theme-color(primary);
              margin-right: 10px;
              margin-bottom: 0;
              white-space: nowrap;
              font-size: 1rem;
            }

            p.ticket-no {
              font-weight: 500;
              margin-right: 5px;
              margin-bottom: 0;
              font-size: 0.9rem;
            }

            p.subject {
              font-weight: 600;
              color: darken(color(gray-light), 30%);
              margin-bottom: 0;
              @extend %ellipsor;
              font-size: 1rem;
            }
          }

          .message {
            p {
              color: #333;
              font-size: 0.9rem;
              margin-bottom: 0.6rem;
              margin-top: 3px;
              @extend %ellipsor;
            }
          }
        }

        .actions {
          .dropdown-menu {
            left: -90%;
          }
        }
      }

      .time-hist {
        p.Last-responded {
          font-weight: 400;
          color: lighten(color(gray-light), 5%);
          font-size: 0.9rem;
          margin-bottom: 0;
          margin-right: 20px;

          &:before {
            content: "Last Responded :";
            display: inline-block;
            margin-right: 10px;
          }
        }

        p.due-on {
          font-weight: 400;
          color: lighten(color(gray-light), 5%);
          font-size: 0.9rem;
          margin-bottom: 0;
          margin-right: 20px;

          &:before {
            content: "Due in :";
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
