/* Navbar */
@mixin menu-style {
  @include media-breakpoint-up(md) {
    position: absolute;
    width: calc(100% - 1px);
    left: 0;
    right: 0;
    top: 46px;
    z-index: 999;
    background: darken($horizontal-bottom-menu-item-bg,2%);
    border-top: none;
    box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
  }
  @include media-breakpoint-down(md) {
    position: relative;
    top: 0;
    padding-top: 20px;
    box-shadow: none;
  }
}

.navbar {
  &.horizontal-layout {
    font-family: $type-1;
    background: $horizontal-top-menu-bg;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);

    .navbar-brand-wrapper {
      width: $navbar-brand-wrapper-width;
      height: $navbar-height;

      .navbar-brand {
        color: $white;
        font-size: 1.5rem;
        line-height: 55px;
        margin-right: 0;
        padding: 0.25rem 0;

        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }

        img {
          max-width: 100%;
          height: 28px;
          margin: auto;
          vertical-align: middle;
        }
      }

      .brand-logo-mini {
        display: none;

        img {
          width: auto;
          max-width: 100%;
          height: 28px;
          margin-left: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        width: $navbar-brand-wrapper-mini-width;

        .brand-logo {
          display: none;
        }

        .brand-logo-mini {
          display: block;
        }
      }
    }

    .navbar-menu-wrapper {
      color: $white;
      padding-left: 15px;
      width: calc(100% - #{$navbar-brand-wrapper-width});

      .navbar-nav {
        flex-direction: row;
        align-items: center;

        .nav-item {
          margin-left: 1rem;
          margin-right: 1rem;

          .nav-link {
            color: $horizontal-menu-item-color;
            font-size: $navbar-font-size;
            vertical-align: middle;

            i {
              font-size: $navbar-icon-font-size;
              vertical-align: middle;
            }
          }
        }
      }

      .search-field {
        .input-group-prepend {
          .input-group-text {
            @include border-radius(50px 0 0 50px);
            background: rgba(213,220,236,0.15);
            border: none;
          }
        }

        .form-control {
          width: 50%;
          background: $horizontal-bottom-menu-item-bg;
          border: none;
          @include border-radius(0 50px 50px 0);
        }
        @include media-breakpoint-down(md) {
          margin-left: auto;

          .rtl & {
            margin-right: auto;
            margin-left: 0;
          }
        }

        .rtl & {
          .input-group-prepend {
            .input-group-text {
              @include border-radius(0 50px 50px 0);
            }
          }

          .form-control {
            @include border-radius(50px 0 0 50px);
          }
        }
      }

      .navbar-toggler {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          padding-right: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        width: calc(100% - #{$navbar-brand-wrapper-mini-width});
      }
      .rtl &{
        padding-left: 0;
        .navbar-toggler{
          padding-left: 0;
        }
      }
    }

    .nav-bottom {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      @include display-flex;
      @include align-items(center);
      @include transition-duration(3s);
      @include transition-property(position,left,right,top,z-index);
      @include media-breakpoint-down(md) {
        display: none;
      }

      .page-navigation {
        float: left;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        z-index: 99;
        background: $horizontal-bottom-menu-item-bg;
        @include transition-duration(0.2s);
        @include transition-property(background, box-shadow);
        @include media-breakpoint-down(md) {
          border: none;
        }

        > .nav-item {
          line-height: 1;
          width: 16.66%;
          text-align: center;
          @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
            border-right: none;
            text-align: left;
          }

          &:last-child {
            border-right: none;
          }

          > .nav-link {
            color: $horizontal-bottom-menu-item-color;
            padding: 15px 10px;
            line-height: 1;
            font-weight: 600;
            @include transition-duration(0.2s);

            .link-icon {
              margin-right: 10px;
              font-size: 1rem;
              color: theme-color(primary);

              .rtl & {
                margin-right: 0;
                margin-left: 10px;
              }
            }

            .menu-arrow {
              margin-left: 5px;
              font-size: 15px;
              display: inline-block;
              @include transform(rotate(0deg));
              @include transform-origin(center);
              @include transition-duration($action-transition-duration);

              &:before {
                font: normal normal normal 24px/1 "Material Design Icons";
                content: "\F35D";
                font-size: inherit;
              }
            }
          }

          &:hover {
            .submenu {
              display: block;
              background: darken($horizontal-bottom-menu-item-bg,2%);
            }

            > .nav-link {
              background: darken($horizontal-bottom-menu-item-bg,2%);

              .menu-arrow {
                @include transform(rotate(-180deg));
              }
            }
          }

          &.active {
            > .nav-link {
              background: $bluish-gradient;
              color: $white;

              .link-icon,
              .menu-arrow {
                color: $white;
              }
            }
          }

          .submenu {
            display: none;
            @extend .dropdownAnimation;

            ul {
              list-style-type: none;
              padding-left: 0;

              li {
                display: block;
                line-height: 20px;

                a {
                  display: block;
                  padding: 5px 10px;
                  font-weight: 600;
                  color: theme-color(dark);
                  text-decoration: none;
                  text-align: left;
                  margin: 4px 0;
                  @include ellipsor;
                  @include transition-duration(0.2s);
                  @include transition-property(background);
                  @include border-radius(6px);

                  &:hover {
                    background: rgba(theme-color(primary),0.1);
                  }
                }

                &.active {
                  a {
                    background: rgba(theme-color(primary),0.1);
                  }
                }
              }
            }
          }

          &:not(.mega-menu) {
            .submenu {
              position: relative;
              top: -100%;
              @include media-breakpoint-down(md) {
                top: 0;
              }

              ul {
                @include menu-style;
                padding: 15px;
                @include media-breakpoint-down(md) {
                  padding: 0 23px;
                }
              }
            }
          }

          &.mega-menu {
            .submenu {
              @include menu-style;
              left: 0;
              padding: 25px;

              .category-heading {
                font-size: 12px;
                font-weight: 600;
                text-align: left;
                color: theme-color(primary);
                padding-bottom: 15px;
                border-bottom: 1px solid rgba($border-color,0.6);
              }
              @include media-breakpoint-down(md) {
                padding: 0 32px;

                .col-group-wrapper {
                  margin-left: 0;
                  margin-right: 0;

                  .col-group {
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: 20px;

                    .category-heading {
                      margin-bottom: 0;

                      &:after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .rtl & {
          padding-right: 0;
        }
      }

      &.header-toggled {
        display: block;
      }

      &.fixed-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        @include media-breakpoint-down(md) {
          position: relative;
        }

        .page-navigation {
          margin-bottom: 0;
          border-bottom: none;
          background: $white;
          box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
          -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
        }
      }
    }
  }
}
